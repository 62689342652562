import urlRegexSafe from "url-regex-safe";
import { HeroImage, CompanyLogo, DiveCompany } from "~/types/shared";
import { Json } from "~/types/supabase";
import {
  CONSERVATION_ORGANISATIONS,
  DIVE_CENTRES,
  DIVE_OPERATORS,
  DIVE_RESORTS,
  LIVEABOARDS,
} from "./routes";

export const COMPANY_TYPES: {
  [key: string]: DiveCompany["company_type"];
} = {
  OPERATOR: "operator",
  CENTRE: "centre",
  RESORT: "resort",
  LIVEABOARD: "liveaboard",
  CONSERVATION: "conservation",
};

export const CONSERVATION_TYPES = {
  NGO: "ngo",
  FOR_PROFIT: "for-profit",
} as const;

export type ConservationOrgTypes =
  | (typeof CONSERVATION_TYPES)[keyof typeof CONSERVATION_TYPES]
  | "";

export const ORGANISATION_LABELS = {
  [COMPANY_TYPES.OPERATOR]: "Dive Operators",
  [COMPANY_TYPES.CENTRE]: "Dive Centres",
  [COMPANY_TYPES.RESORT]: "Dive Resorts",
  [COMPANY_TYPES.LIVEABOARD]: "Liveaboards",
  [COMPANY_TYPES.CONSERVATION]: "Conservation Organisations",
} as const;

export const COMPANY_TYPE_TO_ROUTE = {
  [COMPANY_TYPES.OPERATOR]: DIVE_OPERATORS,
  [COMPANY_TYPES.CENTRE]: DIVE_CENTRES,
  [COMPANY_TYPES.RESORT]: DIVE_RESORTS,
  [COMPANY_TYPES.LIVEABOARD]: LIVEABOARDS,
  [COMPANY_TYPES.CONSERVATION]: CONSERVATION_ORGANISATIONS,
} as const;

export type DiveCompanyFormInputs = {
  company_type: DiveCompany["company_type"] | null | "";
  company_name: string | null;
  conservation_organisation_name: string | null;
  country: string | null;
  alpha_2?: string | null;
  country_id: string | null;
  country_code: string | null;
  location: string | null;
  place_id: string | null;
  latitude: string | number | null;
  longitude: string | number | null;
  // For owners / edit only
  id?: string | null;
  conservation_type: string | null;
  conservation_activities: Json;
  charity_number: string | null;
  description?: string | null;
  web_url?: string | null;
  instagram_url?: string | null;
  twitter_url?: string | null;
  facebook_url?: string | null;
  youtube_url?: string | null;
  companyHero?: HeroImage;
  companyLogo?: CompanyLogo;
};

export type DiveCompanyErrors = {
  [K in keyof DiveCompanyFormInputs]?: string | null;
} & {
  misc?: string;
};

export const diveCompanyFormValidator = (
  values: Partial<DiveCompanyFormInputs>
) => {
  return {
    company_type: !values.company_type
      ? "Please select the company type"
      : null,
    company_name: !values.company_name ? "Please enter the name" : null,
    country_id: !values.country_id ? "Please select a country" : null,
    location: !values.country_id
      ? "Please select a country first"
      : !values.location
      ? "Please select a location"
      : null,
    place_id: !values.place_id
      ? "Please select a location from the list"
      : null,
    latitude: !values.latitude ? "Please enter the resort name" : null,
    longitude: !values.longitude ? "Please enter the resort name" : null,
    description:
      values.description &&
      typeof values.description === "string" &&
      values.description?.length > 500
        ? `Description must be less than 500 characters.`
        : null,
    web_url:
      values.web_url && !values.web_url.match(urlRegexSafe({ strict: true }))
        ? "Please enter a valid URL, e.g. https://example.com"
        : null,
    instagram_url:
      values.instagram_url &&
      !values.instagram_url.match(urlRegexSafe({ strict: true }))
        ? "Please enter a valid URL, e.g. https://instagram.com/username"
        : null,
    twitter_url:
      values.twitter_url &&
      !values.twitter_url.match(urlRegexSafe({ strict: true }))
        ? "Please enter a valid URL, e.g. https://x.com/username"
        : null,
    facebook_url:
      values.facebook_url &&
      !values.facebook_url.match(urlRegexSafe({ strict: true }))
        ? "Please enter a valid URL, e.g. https://facebook.com/username"
        : null,
    youtube_url:
      values.youtube_url &&
      !values.youtube_url.match(urlRegexSafe({ strict: true }))
        ? "Please enter a valid URL, e.g. https://youtube.com/username"
        : null,
  };
};
